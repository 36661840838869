import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'
import { UseQueryOptions, useQuery } from 'react-query'
import { useFavoritesFilters } from '../favorites.param'
import { removeEmptyFieldObject } from '@plandi/common'
import { favoritesApi } from '../favorites.api'

export const useQueryGoodsFavorites = (
  options?: UseQueryOptions<
    components['schemas']['ProductFavoriteListResource']['data'],
    AxiosError,
    components['schemas']['ProductFavoriteListResource']['data']
  >
) => {
  const { page, sort } = useFavoritesFilters()
  const params = removeEmptyFieldObject({ sort, page })
  return useQuery(
    ['favoriteGoods'],
    () => favoritesApi.getFavoriteGoods(params),
    options
  )
}
