import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'

import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'
import { pushSuccessNotification } from '@plandi/common'
import { useThunkDispatch } from '../../../../store'
import { favoritesApi } from '../favorites.api'

export const useMutationCreateFeedbackTender = (
  tenderId?: number,
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    components['schemas']['FeedbackAdvertMessageRequest']
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()

  const mutation = useMutation(
    (requestBody) => {
      if (!tenderId) {
        throw new Error('Отсутствует идентификатор заказа')
      }

      return favoritesApi.createFeedbackTender(tenderId, requestBody)
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        dispatch(pushSuccessNotification('Отклик успешно создан'))

        queryClient.setQueriesData<
          | (components['schemas']['SpecialistTenderResource'] &
              components['schemas']['CompanyTenderResource'])
          | (components['schemas']['SpecialistTenderResource'][] &
              components['schemas']['CompanyTenderResource'][])
        >('ADVERT_TENDERS_KEY', (tenders) =>
          Array.isArray(tenders)
            ? tenders?.map((tender) =>
                tender.id === tenderId
                  ? {
                      ...tender,
                      isFeedback: true
                    }
                  : tender
              )
            : { ...tenders, isFeedback: true }
        )

        options?.onSuccess?.(data, variables, context)
      }
    }
  )

  return mutation
}
