import { components } from '@plandi/common/src/generated'
import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'
import { MyProfileSpecialist } from '../../../profile/common'
import { favoritesApi } from '../favorites.api'

export const useQueryMyProfileCompany = <
  T = components['schemas']['ProfileCompanyMeResource'] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['ProfileCompanyMeResource'] | undefined,
    AxiosError,
    T
  >
) => {
  const data = useQuery(
    'MY_PROFILE_COMPANY_KEY',
    favoritesApi.getMyProfileCompany,
    options
  )

  return data
}

export const useQueryMyProfileSpecialist = <
  T = MyProfileSpecialist | undefined
>(
  options?: UseQueryOptions<MyProfileSpecialist | undefined, AxiosError, T>
) =>
  useQuery(
    'MY_PROFILE_SPECIALIST_KEY',
    favoritesApi.getMyProfileSpecialist,
    options
  )

export const useQueryProfileCity = <
  T = components['schemas']['CityResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['CityResource'][] | undefined,
    AxiosError,
    T
  >
) => useQuery('PROFILE_CITY_KEY', favoritesApi.getProfileCities, options)

export const useQueryProfileCountry = <
  T = components['schemas']['CountryResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['CountryResource'][] | undefined,
    AxiosError,
    T
  >
) => useQuery('PROFILE_COUNTRY_KEY', favoritesApi.getProfileCountries, options)

export const useQueryProfileCompanyKindOfActivities = <
  T = components['schemas']['ActivityKindResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['ActivityKindResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const data = useQuery(
    'PROFILE_COMPANY_ACTIVITY_KINDS_KEY',
    favoritesApi.getProfileCompanyActivityKinds,
    options
  )

  return data
}

export const useQueryProfileSpecialistSpecializations = <
  T = components['schemas']['SpecializationResource'][] | undefined
>(
  options?: UseQueryOptions<
    components['schemas']['SpecializationResource'][] | undefined,
    AxiosError,
    T
  >
) => {
  const data = useQuery(
    'PROFILE_SPECIALIST_SPECIALIZATIONS',
    favoritesApi.getProfileSpecialistSpecializations,
    options
  )

  return data
}
