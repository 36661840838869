import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import { pushSuccessNotification, useLocale } from '@plandi/common'

import { useThunkDispatch } from 'src/store'
import { projectKeys } from '../favorites.keys'
import { favoritesApi } from '../favorites.api'

export const useMutationDeleteProject = (
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()

  const mutation = useMutation(
    (projectId) => favoritesApi.deleteProject(projectId),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? 'Проект успешно удален'
              : 'The project was successfully deleted'
          )
        )

        queryClient.invalidateQueries(projectKeys.all)

        options?.onSuccess?.(data, variables, context)
      }
    }
  )

  return mutation
}
