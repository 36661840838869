import {
  Grid,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import React, { FC } from 'react'

import {
  SERVICES_URL,
  sorting,
  SORTING_NEW,
  SORTING_POPULAR_FAVORITE,
  SORTING_USER_STATISTIC,
  useTranslation
} from '@plandi/common'
import { EmptyContent, TabsComponent } from 'src/common'
import {
  AdvertTenderCard,
  FAVORITES_TYPE,
  getFavoritesTypesArr
} from '../common'
import { ADVERT_TENDER_TYPE } from '../common/favorites.constants'
import { useFavoritesFilters } from '../common/favorites.param'
import { AdvertTenderType } from '../common/favorites.types'
import { useAdvertFavoriteStyles } from './advert-favorites.styles'
import { useQueryAdvertsFavorites } from '../common/queries/use-query-adverts-favorites '

type AdvertFavoritesProps = {
  type?: AdvertTenderType
  onTabChange: (value: FAVORITES_TYPE) => void
}

export const AdvertFavorites: FC<AdvertFavoritesProps> = ({
  type = ADVERT_TENDER_TYPE.ADVERTS,
  onTabChange
}) => {
  const classes = useAdvertFavoriteStyles()
  const theme = useTheme()
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const { setFavoritesFilters, sort, page } = useFavoritesFilters()
  const { data: translate } = useTranslation()

  const { data: advertsFavorites, isLoading: isAdvertsLoading } =
    useQueryAdvertsFavorites()

  const handlerMorePage = (
    event: React.ChangeEvent<unknown>,
    pageValue: number
  ) => {
    setFavoritesFilters({ page: pageValue })
  }

  const advertsSortOptions = [
    SORTING_NEW,
    SORTING_USER_STATISTIC,
    SORTING_POPULAR_FAVORITE
  ]

  if (!isAdvertsLoading && !advertsFavorites?.data?.length) {
    return (
      <EmptyContent
        message={translate?.messages?.['main-title-job']}
        showButton
        buttonProps={{
          href: SERVICES_URL.job + '/applicants',
          text: translate?.messages?.button
        }}
      />
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        {isTabletScreen && (
          <TabsComponent
            tabListClassName={classes.tabList}
            tabClassName={classes.tab}
            onTabChange={onTabChange}
            tabsValues={getFavoritesTypesArr(translate)}
          />
        )}
        <TextField
          select
          value={sort || SORTING_NEW}
          onChange={(e) => {
            setFavoritesFilters({
              sort: e.target.value as typeof sort
            })
          }}
          fullWidth
          className={classes.sort}
        >
          {advertsSortOptions.map((key) => (
            <MenuItem key={key} value={key}>
              {sorting(translate)[key]}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div>
        {advertsFavorites?.data?.map((advert) => (
          <AdvertTenderCard
            type={type}
            {...advert}
            key={advert?.id}
            isFavorite={true}
          />
        ))}
      </div>
      <Grid item xs={12}>
        <div className={classes.paginationBlock}>
          <Pagination
            count={advertsFavorites?.last_page || 1}
            onChange={handlerMorePage}
            showFirstButton
            showLastButton
            size={isMobileScreen ? 'small' : 'medium'}
            page={page || 1}
          />
        </div>
      </Grid>
    </div>
  )
}
