import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from 'react-query'
import { components } from '@plandi/common/src/generated'
import { removeEmptyFieldObject } from '@plandi/common'

import { projectKeys } from '../favorites.keys'
import { favoritesApi } from '../favorites.api'
import { QueryParamsProjectsFavorites } from '../types'

export const useQueryProjectsFavorites = <
  T = components['schemas']['ProjectMarketGetResourceItems'] | undefined
>(
  params?: QueryParamsProjectsFavorites,
  options?: UseQueryOptions<
    components['schemas']['ProjectMarketGetResourceItems'] | undefined,
    AxiosError,
    T
  >
) => {
  const { sort, ...paramsOptions } = removeEmptyFieldObject(params ?? {})
  const normalizeParams = paramsOptions
  if (sort) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    normalizeParams.filter = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...normalizeParams.filter,
      sort
    }
  }
  return useQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    projectKeys.favorite(normalizeParams),
    () => favoritesApi.getProjectsFavorites(normalizeParams),
    options
  )
}
