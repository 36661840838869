import React, { useMemo, useState } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardMedia from '@material-ui/core/CardMedia'
import MaterialLink from '@material-ui/core/Link'
import CardContent from '@material-ui/core/CardContent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { components } from '@plandi/common/src/generated'
import VisibilityIcon from '@material-ui/icons/Visibility'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import { useModelCardStyles } from './model-card.styles'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import IconButton from '@material-ui/core/IconButton'
import { ReactComponent as PersonIcon } from '../../../../assets/personicon.svg'
import { ReactComponent as DownloadsCountIcon } from '../../../../assets/downloadsCountIcon.svg'
import { Tooltip } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'

import {
  useMutationAddFavoriteModel,
  useMutationDeleteFavoriteModel
} from '../mutations'
import { SERVICES_URL, useTranslation } from '@plandi/common'

type ProjectCardProps = components['schemas']['ModelMarketItems']

export const ModelCard: React.FC<ProjectCardProps> = (props) => {
  const [isFavorite, setIsFavorite] = useState(props.isFavorite)
  const classes = useModelCardStyles()
  const router = useRouter()
  const mutationAddFavorite = useMutationAddFavoriteModel()
  const mutationDeleteFavorite = useMutationDeleteFavoriteModel()
  const { data: translation } = useTranslation()
  let name = ''
  if (props.name) {
    name = props.name.length > 51 ? props.name.slice(0, 52) + '...' : props.name
  }
  let downloadCount = '0'
  if (props.download_count) {
    downloadCount =
      String(props.download_count).length > 3
        ? String(props.download_count).slice(0, 3) + '+'
        : String(props.download_count)
  }

  const handleDeleteFavorite = () => {
    if (props.id) {
      mutationDeleteFavorite.mutate(props.id)
      setIsFavorite(false)
    }
  }
  const handleAddFavorite = () => {
    if (props.id) {
      mutationAddFavorite.mutate(props.id)
      setIsFavorite(true)
    }
  }

  const handleChangeRouteProjectsUser = () => {
    if (props.user?.id) {
      router.push({
        pathname: SERVICES_URL.shop + '/models/users/[userId]',
        query: {
          userId: props.user?.id
        }
      })
    }
  }
  const nameForCard = useMemo(() => {
    return `${props?.user?.name || ''} ${props?.user?.lastName || ''}`
  }, [props])

  const dateFormat = (dt: string | null | undefined) => {
    if (!dt) return ''
    return moment(dt).format('DD.MM.YYYY')
  }

  return (
    <Card elevation={0} className={classes.root}>
      <div className={classes.cardHeader}>
        <div className={classes.header_publication}>
          {`${translation?.['publish-at']}:`}
          <span className={classes.headerDate}>
            {dateFormat(props.publishedAt?.date)}
          </span>
        </div>
        <div className={classes.headerInfoIcons}>
          <div className={classes.headerYeys}>
            <DownloadsCountIcon color="primary" fontSize="small" />
            <span className={classes.downloadCountNumber}>{downloadCount}</span>
          </div>
          <div className={classes.headerYeys}>
            <VisibilityIcon color="primary" fontSize="small" />
            <span className={classes.viewdNumber}>{props.view || '0'}</span>
          </div>
        </div>
      </div>
      <CardContent className={classes.content}>
        <CardMedia
          component="img"
          className={classes.img}
          image={
            props.cover?.filename
              ? `https://plandi-storage.storage.yandexcloud.net/storage/${props.cover.filename}`
              : ''
          }
          title={props.name ?? ''}
        />
        <div className={classes.infoMobile}>
          <Link
            href={{
              pathname: SERVICES_URL.shop + '/models/[slugName]/[modelId]',
              query: {
                modelId: props.id,
                slugName: props.slug_name
              }
            }}
            passHref
          >
            <Typography
              variant="subtitle1"
              className={classes.projectName}
              component={MaterialLink}
              color="textPrimary"
              noWrap
            >
              <Tooltip title={props.name || ''}>
                <div className={classes.projectName}>{name}</div>
              </Tooltip>
            </Typography>
          </Link>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            className={clsx(classes.discription, classes.descriptionMobile)}
          >
            {props.category?.title}
          </Typography>
        </div>
        <div className={classes.info}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            className={classes.discription}
          >
            {props.category?.title}
          </Typography>
          <div className={classes.infoBottom}>
            <Typography
              variant="body2"
              noWrap
              className={classes.name}
              onClick={handleChangeRouteProjectsUser}
              component={MaterialLink}
              underline={props?.user?.id ? 'hover' : 'none'}
            >
              <PersonIcon className={classes.iconName} />
              {(props?.user?.name || props?.user?.lastName) && (
                <Tooltip title={nameForCard}>
                  <div className={classes.fullName}>{nameForCard}</div>
                </Tooltip>
              )}
            </Typography>
            {isFavorite ? (
              <IconButton
                component="span"
                size="small"
                onClick={handleDeleteFavorite}
              >
                <StarOutlinedIcon color="primary" />
              </IconButton>
            ) : (
              <IconButton
                onClick={handleAddFavorite}
                component="span"
                size="small"
              >
                <StarBorderIcon color="primary" />
              </IconButton>
            )}
          </div>
        </div>
      </CardContent>
      <Grid container spacing={2} className={classes.infoBottomMobile}>
        {isFavorite ? (
          <IconButton
            component="span"
            size="small"
            onClick={handleDeleteFavorite}
          >
            <StarOutlinedIcon color="primary" />
          </IconButton>
        ) : (
          <IconButton onClick={handleAddFavorite} component="span" size="small">
            <StarBorderIcon color="primary" />
          </IconButton>
        )}
        <Typography
          variant="body2"
          noWrap
          className={clsx(classes.name, classes.mobileName)}
          onClick={handleChangeRouteProjectsUser}
          component={MaterialLink}
          underline={props.userId ? 'hover' : 'none'}
        >
          <PersonIcon className={classes.iconName} />
          {(props?.user?.name || props?.user?.lastName) && (
            <Tooltip title={nameForCard}>
              <div className={classes.fullName}>{nameForCard}</div>
            </Tooltip>
          )}
        </Typography>
      </Grid>
    </Card>
  )
}
