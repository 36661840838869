import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import {
  Masonry,
  getServiceURL,
  sorting,
  SORTING_CHEAP,
  SORTING_EXPENSIVE,
  SORTING_NEW,
  useLocale,
  TranslationDictionary
} from '@plandi/common'
import { EmptyContent, TabsComponent } from 'src/common'
import { useGoodsFavoritesStyles } from './goods-favorites.styles'
import { useGoodsFavoritesFilters } from './goods-favorites.param'
import { GoodsFavoritesSkeleton } from './goods-favorites-skeleton'
import {
  FAVORITES_TYPE,
  getFavoritesTypesArr,
  useQueryGoodsFavorites
} from '../common'
import { ProductCard } from '../common/product-card'

type GoodsFavoritesProps = {
  onTabChange: (value: FAVORITES_TYPE) => void
  translate?: TranslationDictionary['data']
  locale: string
}

export const GoodsFavorites: React.FC<GoodsFavoritesProps> = ({
  onTabChange,
  translate
}) => {
  const classes = useGoodsFavoritesStyles()
  const theme = useTheme()
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const { sort, page, setProjectsFavoritesFilters } = useGoodsFavoritesFilters()

  const { data: goodsFavorites, isLoading } = useQueryGoodsFavorites()
  const locale = useLocale()
  const breakpointColsMasonry = {
    default: 3,
    [theme.breakpoints.values.xl]: 3,
    [theme.breakpoints.values.lg]: 2,
    [theme.breakpoints.values.sm]: 1
  }

  const handlerMorePage = (
    event: React.ChangeEvent<unknown>,
    pageValue: number
  ) => {
    setProjectsFavoritesFilters({ page: pageValue })
  }

  if (isLoading) {
    return <GoodsFavoritesSkeleton />
  }

  return (
    <div>
      {goodsFavorites?.items.length ? (
        <>
          <div className={classes.head}>
            {isTabletScreen && (
              <TabsComponent
                onTabChange={onTabChange}
                tabClassName={classes.tab}
                tabListClassName={classes.tabList}
                tabsValues={getFavoritesTypesArr(translate)}
              />
            )}
            <TextField
              select
              value={sort ?? 'new'}
              onChange={(event) =>
                setProjectsFavoritesFilters({
                  sort: event.target.value as typeof sort
                })
              }
              fullWidth
              className={classes.sort}
            >
              {[SORTING_NEW, SORTING_CHEAP, SORTING_EXPENSIVE].map((key) => (
                <MenuItem key={key} value={key}>
                  {sorting(translate)[key]}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Masonry breakpointCols={breakpointColsMasonry}>
            {goodsFavorites?.items.map((productFavorites) => (
              <ProductCard
                slug_name={''}
                key={productFavorites.id}
                {...productFavorites}
              />
            ))}
          </Masonry>
          {goodsFavorites?.pageTotal ? (
            <Grid item xs={12}>
              <div className={classes.paginationBlock}>
                <Pagination
                  count={goodsFavorites?.pageTotal}
                  onChange={handlerMorePage}
                  showFirstButton
                  showLastButton
                  size={isMobileScreen ? 'small' : 'medium'}
                  page={page}
                />
              </div>
            </Grid>
          ) : null}
        </>
      ) : (
        <EmptyContent
          message={
            locale === 'en'
              ? // eslint-disable-next-line quotes
                "You don't have any products added to your favorites yet,\
               add at least one project to your favorites"
              : 'У вас пока нет товаров, добавленых в избранное,\
               добавьте в избранное по крайней мере один проект'
          }
          showButton
          buttonProps={{
            href: getServiceURL(locale).shop + '/projects',
            text: translate?.favorites.button
          }}
        />
      )}
    </div>
  )
}
