import React from 'react'
import Button from '@material-ui/core/Button'
import { components } from '@plandi/common/src/generated'
import { useRouter } from 'next/router'
import Dialog from '@material-ui/core/Dialog'
import { useFormik } from 'formik'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { URLS } from 'src/router/urls'
import { useAdvertTenderFeedbackModalStyles } from './advert-tender-feedback-modal.styles'
import { useMutationCreateFeedbackTender } from '../mutations'
import { createFeedbackTenderValidationSchema } from '../favorites.validation'
import { SERVICES_URL, useTranslation } from '@plandi/common'

type AdvertTenderFeedbackModalProps = {
  tenderId?: number
  isOpen: boolean
  onClose: () => void
}

export const AdvertTenderFeedbackModal: React.FC<
  AdvertTenderFeedbackModalProps
> = (props) => {
  const router = useRouter()
  const classes = useAdvertTenderFeedbackModalStyles()
  const { data: translate } = useTranslation()
  const mutationCreateFeedbackTender = useMutationCreateFeedbackTender(
    props.tenderId,
    {
      onSuccess: () => {
        props.onClose()

        if (router.pathname !== URLS.applicants) {
          router.push(`${SERVICES_URL.job}/applicants`)
        }
      }
    }
  )

  const formik = useFormik<
    components['schemas']['FeedbackAdvertMessageRequest']
  >({
    initialValues: {
      message: ''
    },
    validationSchema: createFeedbackTenderValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (props.tenderId) {
        mutationCreateFeedbackTender.mutate(values)
      }

      setSubmitting(false)
    }
  })

  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
      maxWidth="sm"
      fullWidth
      classes={{ paperWidthSm: classes.root }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h5">{translate?.['response-to-ad']}</Typography>

        <IconButton
          onClick={props.onClose}
          size="small"
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          multiline
          variant="outlined"
          value={formik.values.message}
          onChange={formik.handleChange}
          name="message"
          label={translate?.['message-text']}
          helperText={formik.errors.message}
          error={Boolean(formik.errors.message)}
          disabled={formik.isSubmitting}
          fullWidth
          maxRows={4}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => formik.handleSubmit()}
          disabled={formik.isSubmitting}
        >
          {translate?.auth?.send}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
