import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import MaterialLink from '@material-ui/core/Link'
import CardContent from '@material-ui/core/CardContent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { components } from '@plandi/common/src/generated'
import { moneyFormat, SERVICES_URL } from '@plandi/common'

import { useProductCardStyles } from './product-card.styles'
import { ProductCardFavoriteIcon } from './product-card-favorite-icon'
import { Tooltip } from '@material-ui/core'
// import { ProductAuthorMenu } from '../product-author-menu'

type ProductCardProps = components['schemas']['ProductFavoriteResource']

export const ProductCard: React.FC<ProductCardProps> = (props) => {
  const classes = useProductCardStyles()
  const router = useRouter()

  const renderActionIcon = () => {
    return (
      <ProductCardFavoriteIcon
        className={classes.menu}
        isFavorite={props.isFavorite}
        productId={props.id}
      />
    )
  }

  const handleChangeRouteProductUser = () => {
    if (props.profile.user.id) {
      router.push({
        pathname: SERVICES_URL.shop + '/goods/users/[userId]',
        query: {
          userId: props.profile.user.id
        }
      })
    }
  }
  console.log(props)
  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.content}>
        <CardMedia
          component="img"
          className={classes.img}
          src={props.cover ?? ''}
          title={props.name ?? ''}
        />
        <Link
          href={{
            pathname: '/shop/goods/[slugName]/[productId]',
            query: {
              productId: props.id,
              slugName: props.slug_name
            }
          }}
          passHref
        >
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.projectName}
            component={MaterialLink}
            color="textPrimary"
          >
            <Tooltip title={props.name || ''}>
              <div className={classes.projectName}>{props.name}</div>
            </Tooltip>
          </Typography>
        </Link>
        <div className={classes.info}>
          <Typography
            variant="body2"
            color="textSecondary"
            noWrap
            className={classes.name}
            onClick={handleChangeRouteProductUser}
            component={MaterialLink}
            underline={props.profile.user.id ? 'hover' : 'none'}
          >
            {props?.profile.name && (
              <Tooltip title={props.profile.name}>
                <div className={classes.fullName}>{props.profile.name}</div>
              </Tooltip>
            )}
          </Typography>
        </div>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {renderActionIcon()}
        <Typography className={classes.cost}>
          {props.cost && moneyFormat(props.cost)}
        </Typography>
      </CardActions>
    </Card>
  )
}
