import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { favoritesApi } from '../favorites.api'
import { components } from '@plandi/common/src/generated'
import { removeEmptyFieldObject } from '@plandi/common'
import { useFavoritesFilters } from '../favorites.param'
import { ADVERTS_FAVORITE_TENDERS } from '../favorites.constants'

export const useQueryAdvertsFavorites = (
  options?: UseQueryOptions<
    components['schemas']['FavoritesAdvertsTenderListResource'],
    AxiosError,
    components['schemas']['FavoritesAdvertsTenderListResource']
  >
) => {
  const { page, sort } = useFavoritesFilters()
  const params = removeEmptyFieldObject({ sort })

  const normalizedParams = {
    page: page,
    filter: {
      ...params,
      sort: params.sort || 'new'
    }
  }

  return useQuery(
    [
      ADVERTS_FAVORITE_TENDERS,
      normalizedParams.page,
      normalizedParams.filter.sort
    ],
    () => favoritesApi.getFavoriteAdvertsTenders(normalizedParams),
    options
  )
}
