import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import MaterialLink from '@material-ui/core/Link'
import CardContent from '@material-ui/core/CardContent'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { components } from '@plandi/common/src/generated'
import { moneyFormat, SERVICES_URL } from '@plandi/common'

import { useProjectCardStyles } from './project-card.styles'
import { ProjectCardFavoriteIcon } from './project-card-favorite-icon'
import { ProjectAuthorMenu } from '../project-author-menu'
import { Tooltip } from '@material-ui/core'

type ProjectCardProps = components['schemas']['ProjectMarketGetResource']

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const classes = useProjectCardStyles()
  const router = useRouter()

  const renderActionIcon = () => {
    if (props.isAuthor) {
      return (
        <ProjectAuthorMenu
          className={classes.menu}
          projectId={props.id}
          status="published"
          translate={props.translate}
        />
      )
    }

    return (
      <ProjectCardFavoriteIcon
        className={classes.menu}
        isFavorite={props.isFavorite}
        projectId={props.id}
      />
    )
  }

  const handleChangeRouteProjectsUser = () => {
    if (props.userId) {
      router.push({
        pathname: SERVICES_URL.shop + '/projects/users/[userId]',
        query: {
          userId: props.userId
        }
      })
    }
  }

  return (
    <Card elevation={0} className={classes.root}>
      <CardContent className={classes.content}>
        <CardMedia
          component="img"
          className={classes.img}
          src={props.cover ?? ''}
          title={props.name ?? ''}
        />
        <Link
          href={{
            pathname: SERVICES_URL.shop + '/projects/[slugName]/[projectId]',
            query: {
              projectId: props.id,
              slugName: props.slug_name
            }
          }}
          passHref
        >
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.projectName}
            component={MaterialLink}
            color="textPrimary"
          >
            <Tooltip title={props.name || ''}>
              <div className={classes.projectName}>{props.name}</div>
            </Tooltip>
          </Typography>
        </Link>
        <div className={classes.info}>
          <Typography
            variant="body2"
            color="textSecondary"
            noWrap
            className={classes.name}
            onClick={handleChangeRouteProjectsUser}
            component={MaterialLink}
            underline={props.userId ? 'hover' : 'none'}
          >
            {props?.fullName && (
              <Tooltip title={props.fullName}>
                <div className={classes.fullName}>{props.fullName}</div>
              </Tooltip>
            )}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="span"
            noWrap
          >
            {props.buildingArea ? (
              <>
                {' '}
                {moneyFormat(props.buildingArea, false)} м<sup>2</sup>
              </>
            ) : (
              props.translate?.['area-not-specified']
            )}
          </Typography>
        </div>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        {renderActionIcon()}
        <Typography className={classes.cost}>
          {props.cost && moneyFormat(props.cost)}
        </Typography>
      </CardActions>
    </Card>
  )
}
