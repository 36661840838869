import { AxiosError } from 'axios'
import { favoritesApi } from '../favorites.api'
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query'
import {
  pushDangerNotification,
  pushSuccessNotification,
  useLocale
} from '@plandi/common'
import { useAppDispatch } from '../../../../store'

export const useMutationAddFavoriteTender = (
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { advertId: number; type: 'specialist' | 'company' | 'adverts' }
  >
) => {
  const locale = useLocale()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const failMessage =
    locale === 'ru'
      ? 'Произошла ошибка при попытке добавить заказ в избранное'
      : 'An error occurred when trying to add an order to favorites'

  return useMutation(
    ({ advertId, type }) => {
      return type === 'specialist'
        ? favoritesApi.addFavoriteSpecialistTender(advertId)
        : favoritesApi.addFavoriteCompanyTender(advertId)
    },
    {
      ...options,
      onSuccess(data, variables, context) {
        options?.onSuccess?.(data, variables, context)
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? 'Заказ успешно добавлен в избранное'
              : 'The order has been successfully added to favorites'
          )
        )
        queryClient.invalidateQueries()
      },
      onError(error, variables, context) {
        options?.onError?.(error, variables, context)
        dispatch(pushDangerNotification(failMessage))
      },
      onSettled() {
        queryClient.invalidateQueries()
      }
    }
  )
}
