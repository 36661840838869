import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import {
  dateFormatMask,
  format,
  moneyFormat,
  SERVICES_URL,
  useLocale,
  useTranslation
} from '@plandi/common'
import { components } from '@plandi/common/src/generated'
import MaterialLink from '@material-ui/core/Link'
import Link from 'next/link'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarOutlinedIcon from '@material-ui/icons/StarOutlined'
import { IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Image from 'next/image'

import { URLS } from 'src/router/urls'
import { useAdvertTenderCardStyles } from './advert-tender-card.styles'
import { AdvertTenderFeedbackModal } from '../advert-tender-feedback-modal'
import { useFeedbackButton } from '../use-feedback-button'
import { useTagsAdvert } from '../use-tags-tender'
import { AdvertTenderType } from '../favorites.types'
import {
  useMutationAddFavoriteTender,
  useMutationDeleteFavoriteTender
} from '../mutations'

import { ReactComponent as Freelancer } from '../../../../assets/tabs/freelancer.svg'
import { ReactComponent as Worker } from '../../../../assets/tabs/worker.svg'
import { ReactComponent as Company } from '../../../../assets/tabs/company.svg'

type AdvertTenderCardProps = components['schemas']['CompanyTenderResource'] &
  components['schemas']['SpecialistTenderResource'] & {
    type: AdvertTenderType
  }

export const AdvertTenderCard: React.FC<AdvertTenderCardProps> = (props) => {
  const [isFavorite, setIsFavorite] = useState(props.isFavorite)
  const classes = useAdvertTenderCardStyles()
  const { isOpen, onClose } = useFeedbackButton(props.type)
  const { data: translate } = useTranslation()
  const locale = useLocale()
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const tags = [
    props.location?.country?.name,
    props.location?.city?.name,
    props.isRemoteWork ? 'Удалённая работа' : null
  ]

  const tagSkill = useTagsAdvert({
    skills: props.skills,
    enabled: false
  })

  const specializationsSpecialistMain =
    props.mainActivitySpecializationsSpecialist?.map((item) => item.name)
  // const specializationsSpecialistAcyivity =
  //   props.activitySpecializationsSpecialist?.map((item) => item.name)

  const specializationsCompanyMain =
    props.mainActivitySpecializationsCompany?.map((item) => item.name)
  // const specializationsCompanyActivity =
  //   props.activitySpecializationsCompany?.map((item) => item.name)

  const cutDesc =
    props?.description && props.description.length > 198
      ? props.description.slice(0, 198) + '...'
      : props.description

  const deleteFavoriteTenderSpecialistMutation =
    useMutationDeleteFavoriteTender()
  const addFavoriteTenderSpecialistMutation = useMutationAddFavoriteTender()

  const handleDeleteFavorite = () => {
    if (!props.id) return

    setIsFavorite(false)
    deleteFavoriteTenderSpecialistMutation.mutate(
      { advertId: props.id },
      {
        onError: () => setIsFavorite(true)
      }
    )
  }
  const handleAddFavorite = () => {
    if (!props.id) return

    setIsFavorite(true)
    addFavoriteTenderSpecialistMutation.mutate(
      { advertId: props.id, type: props.type },
      {
        onError: () => setIsFavorite(false)
      }
    )
  }

  return (
    <Card className={classes.card} elevation={2}>
      <div style={{ marginBottom: 6 }}>
        {props.types?.includes('freelancer') && (
          <>
            {locale === 'ru' ? (
              <Freelancer style={{ marginRight: 20 }} />
            ) : (
              <Image
                width="83"
                height="23"
                src="/assets/tabs/freelancer.png"
                alt=""
                layout="fixed"
              />
            )}
          </>
        )}
        {props.types?.includes('worker') && (
          <>
            {locale === 'ru' ? (
              <Worker style={{ marginRight: 20 }} />
            ) : (
              <Image
                width="83"
                height="23"
                src="/assets/tabs/worker.png"
                alt=""
                layout="fixed"
              />
            )}
          </>
        )}
        {props.types?.includes('company') && (
          <>
            {locale === 'ru' ? (
              <Company />
            ) : (
              <Image
                width="83"
                height="23"
                src="/assets/tabs/company.png"
                alt=""
                layout="fixed"
              />
            )}
          </>
        )}
      </div>
      <div className={classes.cardTop}>
        <div className={classes.cardTopLeft}>
          <Link
            key={props.id}
            href={{
              pathname:
                props.types?.includes('freelancer') ||
                props.types?.includes('worker')
                  ? SERVICES_URL.job + URLS.advert.specialist.detailTender
                  : SERVICES_URL.job + URLS.advert.company.detailTender,
              query: {
                tenderId: props.id,
                slugName: props.slug_name || 'no-slug-name'
              }
            }}
            passHref
          >
            <MaterialLink
              color="primary"
              variant="h6"
              className={classes.mainTitle}
            >
              {props.title}
            </MaterialLink>
          </Link>
          {isMobileScreen && (
            <IconButton
              component="span"
              size="small"
              onClick={isFavorite ? handleDeleteFavorite : handleAddFavorite}
            >
              {isFavorite ? (
                <StarOutlinedIcon color="primary" />
              ) : (
                <StarBorderIcon color="primary" />
              )}
            </IconButton>
          )}
        </div>
        <div className={classes.cardTopRight}>
          {props.createdAt && (
            <Typography variant="body2" color="textSecondary" component="div">
              {translate?.['publish-at']}{' '}
              {dateFormatMask(props.createdAt, format.shortFullDate)}
            </Typography>
          )}
          {isMobileScreen && (
            <div className={classes.cardStats}>
              <div className={classes.cardStatsBlock}>
                <VisibilityIcon
                  className={classes.cardStatsIcon}
                  color="primary"
                />
                <span>{props.views_count || 0}</span>
              </div>
              <div className={classes.cardStatsBlock}>
                <Image
                  width={18}
                  height={14}
                  alt=""
                  src="/assets/icons/feedback-icon.svg"
                />
                <span>{props.feedbacks_count || 0}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className={classes.mainDesc}>
          <Typography variant="body2" component="p" color="textSecondary">
            {cutDesc}
          </Typography>
        </div>
        <div className={classes.groupChip}>
          {tags?.map(
            (tag, idx) => tag && <Chip key={idx} label={tag} color="primary" />
          )}
          {specializationsSpecialistMain?.map(
            (tag, idx) => tag && <Chip key={idx} label={tag} color="primary" />
          )}
          {specializationsCompanyMain?.map(
            (tag, idx) => tag && <Chip key={idx} label={tag} color="primary" />
          )}
          {tagSkill?.map(
            (tag, idx) => tag && <Chip key={idx} label={tag} color="primary" />
          )}
        </div>
        <div className={classes.action}>
          <span className={classes.cardBottomLeft}>
            {!isMobileScreen && (
              <>
                {isFavorite ? (
                  <IconButton
                    component="span"
                    size="small"
                    onClick={handleDeleteFavorite}
                  >
                    <StarOutlinedIcon color="primary" />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={handleAddFavorite}
                    component="span"
                    size="small"
                  >
                    <StarBorderIcon color="primary" />
                  </IconButton>
                )}
                <div className={classes.cardStats}>
                  <div className={classes.cardStatsBlock}>
                    <VisibilityIcon
                      className={classes.cardStatsIcon}
                      color="primary"
                    />
                    <span>{props.views_count || 0}</span>
                  </div>
                  <div className={classes.cardStatsBlock}>
                    <Image
                      width={18}
                      height={14}
                      alt=""
                      src="/assets/icons/feedback-icon.svg"
                    />
                    <span>{props.feedbacks_count || 0}</span>
                  </div>
                </div>
              </>
            )}
          </span>
          <Typography variant="body1" component="p" className={classes.price}>
            {props.budget
              ? moneyFormat(props.budget)
              : translate?.['by-agreement']}
          </Typography>
          {isOpen && (
            <AdvertTenderFeedbackModal
              isOpen={isOpen}
              onClose={onClose}
              tenderId={props.id}
            />
          )}
        </div>
      </div>
    </Card>
  )
}
