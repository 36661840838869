import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { useBreadcrumbsStyles } from './breadcrumbs.styles'
import { ReactComponent as HomeIcon } from '../../assets/homeIcon.svg'
import { SERVICES_URL } from '@plandi/common'
import { Typography } from '@material-ui/core'
import { breadcrumbInfoType } from '../layouts'

type BreadCrumbsProps = {
  breadcrumbInfo: Array<breadcrumbInfoType> | undefined
  styles?: React.CSSProperties | undefined
}

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  breadcrumbInfo,
  styles
}) => {
  const classes = useBreadcrumbsStyles()

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator="·"
      className={classes.Breadcrumbs}
      style={styles}
    >
      <a className={classes.BreadCrumbs_Homelink} href={SERVICES_URL.main}>
        <HomeIcon className={classes.homeIcon} />
      </a>
      {breadcrumbInfo &&
        breadcrumbInfo.map((elem: breadcrumbInfoType, index: number) => {
          return index + 1 === breadcrumbInfo.length ? (
            <Typography
              key={elem.title}
              className={classes.BreadCrumbs_links}
              style={{
                color: '#2E86BD',
                marginTop: '5px'
              }}
            >
              {elem.title}
            </Typography>
          ) : (
            <a
              key={elem.title}
              className={classes.BreadCrumbs_links}
              href={elem.href}
            >
              {elem.title}
            </a>
          )
        })}
    </Breadcrumbs>
  )
}
